function setEqualHeight() {
    $(".grid-product .grid-element-content-header-image").matchHeight();
    $(".grid-product .grid-element-title").matchHeight();
    $(".grid-product .product-options").matchHeight();
    $(".grid-product .productshortdesc").matchHeight();
    $(".grid-product .productshortattributes").matchHeight();
    $(".grid-product .prices-small").matchHeight();
    $(".grid-product .nakup").matchHeight();
    $(".grid-news-item .title-wrapper").matchHeight();
    $(".grid-news-item .content_short-wrapper").matchHeight();
    $(".grid-menu-item-022-right .menu-item-row-element").matchHeight();
}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').on("change",function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $menu.css("max-height",maxHeight + "px");
                if ($menu.outerHeight() > maxHeight) {
                    $menu.css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $menu.css("max-height","");
            $menu.css("height","");
        });
    }
});

$(function() {
    $("#main-menu-inverted").smartmenus({
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: -8,
        keepInViewport    : true,
        keepHighlighted   : false,
        markCurrentItem   : false,
        markCurrentTree   : false
    /*}).bind("click.smapi", function(e, item) {
        var $item = $(item);
        if($item.attr("href") === "#") {
            e.preventDefault();
            $('#main-menu').smartmenus('itemActivate', $item);
            return false;
        }*/
    });
});

$('#main-menu-state-inverted').on("change",function(e) {
    var $menu = $('#main-menu-inverted');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $menu.css("max-height",maxHeight + "px");
                if ($menu.outerHeight() > maxHeight) {
                    $menu.css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $menu.css("max-height","");
            $menu.css("height","");
        });
    }
});

$("#search-toggle-button-header-normal, #search-toggle-button-header-inverted, #search-close-button_header_normal").on("click",function(event) {
    event.preventDefault();
    var skelSize       = getSkelSize(),
        slideDirection = 'up',
        effect;
    switch (skelSize) {
        case 'xxxlarge':
            slideDirection = "up";
            break;
        case 'xxlarge':
            slideDirection = "up";
            break;
        case 'xlarge':
            slideDirection = "up";
            break;
        case 'large':
            slideDirection = "up";
            break;
        case 'medium':
            slideDirection = "up";
            break;
        case 'small':
            slideDirection = "up";
            break;
        case 'xsmall':
            slideDirection = "up";
            break;
        default:
            slideDirection = "up";
    }
    if ($("#search-toggle-wrapper_header_normal, #search-toggle-wrapper-header-inverted").is(":visible")) {
        $("#search-toggle-wrapper_header_normal").effect("slide", {mode: "hide", direction: slideDirection, duration: 500});
    } else {
        $("#search-toggle-wrapper_header_normal").effect("slide", {mode: "show", direction: slideDirection, duration: 500});
    }
});

var headerEffectInProgress = false;
$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit = 100;
    switch (current_breakpoint) {
        case "xxxlarge":
            scroll_limit = 100;
            break;
        case "xxlarge":
            scroll_limit = 100;
            break;
        case "large":
            scroll_limit = 100;
            break;
        case "medium":;
            scroll_limit = 50;
            break;
        case "small":
            scroll_limit = 50;
            break;
        case "xsmall":
            scroll_limit = 50;
            break;
        default:
            scroll_limit = 100;
            break;
    }
    if (current_breakpoint_num > -1) {
        if ($(window).scrollTop() < scroll_limit) {
            if ($("#header-main-wrapper-inverted").is(":visible")) {
                if (!headerEffectInProgress) {
                    headerEffectInProgress = true;
                    $("#header-main-wrapper-inverted").effect(
                        "slide",
                        {
                            mode: "hide",
                            direction: "up"
                        },
                        500,
                        function(){
                            headerEffectInProgress = false;
                        }
                    );
                }
            }
        } else {
            if (!$("#header-main-wrapper-inverted").is(":visible")) {
                if (!headerEffectInProgress) {
                    headerEffectInProgress = true;
                    $("#header-main-wrapper-inverted").effect(
                        "slide",
                        {
                            mode: "show",
                            direction: "up"
                        },
                        500,
                        function(){
                            headerEffectInProgress = false;
                        }
                    );
                }
            }
        }
    }
});
